import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Reason Code", "has-filter-inactive": false, "has-active": false, "search-by": "ReasonCode", "ph-search-by": "Search by Reason Code" } }, [_c("text-field", { key: "ReasonCode", attrs: { "data-index": "reasonCode", "title": "Reason Code" } })], 1);
};
var staticRenderFns$1 = [];
var ReasonCode_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "ReasonCode"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReasonCode = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.ctpreasons", "api-url": _vm.apiUrl, "create-route": "/line-performance/reason-code/create", "edit-route": "/line-performance/reason-code/:id" } }, [_c("reason-code")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ReasonCode },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "ctp",
          title: "CTP",
          path: ""
        },
        {
          key: "master-files",
          title: "Master Files",
          path: ""
        },
        {
          key: "reason-code",
          title: "Reason Code",
          path: "/line-performance/reason-code"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
